import account from './account'
import loader from './loader'
import people from './people'
import modal from './modal'
import signature from './signature'
import message from './message'
import report from './report'

const actions = {
	account,
	loader,
	people,
	modal,
	signature,
	message,
	report,
}

export default actions
