const Endpoints = {
  LOGIN: 'login.php',
  GET_PEOPLE: 'person/read.php',
  POST_PERSON: 'person/create.php',
  UPDATE_PERSON: 'person/update.php',
  DELETE_PERSON: (id) => `person/delete.php?personId=${id}`,
  POST_SIGNATURE: 'signature/create.php',
  UPDATE_SIGNATURE: 'signature/update.php',
  GET_SIGNATURE_BY_PERSON: (personId) =>
    `signature/read.php?personId=${personId}`,
  DELETE_SIGNATURE_BY_PERSON: ({ id, personId }) =>
    `signature/delete.php?id=${id}&personId=${personId}`,
  GET_REPORT_NO_SIGNATURE: 'report/nosignature.php',
  GET_REPORT_NO_INFO_SIGNATURE: 'report/noinfosignature.php',
  GET_REPORT_BY_PROCESS: 'report/byprocess.php',
  GET_USERS: 'user/read.php',
  GET_USER: (userId) => `user/single.php?id=${userId}`,
  POST_USER: 'user/create.php',
  UPDATE_USER: 'user/update.php',
  DELETE_USER: (id) => `user/delete.php?id=${id}`,
};

export default Endpoints;
